import class_accommodations from "../../api/accommodations/accommodations"

const state = {
    accommodations: null,
    accommodationsLoading: false,
    accommodationsError: null
}

const getters = {

    get() {
        return state.accommodations ;
    }

}

const mutations = {
    
    set(state, accommodations){
        state.accommodations = accommodations;
        state.accommodationsLoading = false
    },

    setError(state, error) {
        state.accommodationsError = error;
        state.accommodationsLoading = false;
    }
}

const actions = {
    fetch(){
        try {
            if(state.accommodations){
                console.log("Accommodations already exists");
                throw "Accommodations already exists";
            }

            if(state.loadingAccommodations){
                console.log("Accommodation already loading");
                throw "Accommodation already loading";
            }

            state.accommodationsLoading = true;

            const Accommodations = new class_accommodations();
            Accommodations.get_translations(true);
            Accommodations.set_meta(true);
            Accommodations.set_media(true);
            Accommodations.get().then(response => {
                this.commit("accommodations/set", response)
            })
            .catch(error => {
                console.log("[Accommodations] - Error in accommodations", error);
                this.commit("accommodations/setError", error)
            })

        } catch(error) {
            console.log("[Accommodations] Catch error: ", error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}