import price_calculationApi from "../../api/price_calculation/price_calculation" ;
import { i18n } from "../../plugins/i18n";
import dayjs from "dayjs";

const state = {
    isLoading: false,
    priceCalculation: null,
    ageTables: null,
};

// getters
const getters = {

    get(state) {
        return state.priceCalculation ;
    },

    loading(state) {
        return state.isLoading ;
    },

    getAgeTables(state){
        return state.ageTables
    },

    getTotalPersons(state){
        if(state.ageTables){
            return state.ageTables.reduce((accumulator, row) => {
                return accumulator + row.count
            }, 0)
        }
    }

};

const mutations = {

    set(state, priceCalculation) {
        state.isLoading = false ;
        state.priceCalculation = priceCalculation ;
    },

    set_age_tables(state, age_tables) {
        state.ageTables = age_tables;
        if(state.priceCalculation) state.priceCalculation.age_tables = age_tables ;
    },

};

// actions
const actions = {

    // this gets the administration and will save it into the store.
    get(context, checkIsLoading = false) {
        
        try {

            if(checkIsLoading && state.isLoading){
                console.log('priceCalculation isLoading 1') ;
                return ;
            }

            // check for accommodation
            if(!context.rootState.app.settings.accommodation_id){

                // try to get first accommodation_id
                console.log('NO ACCO ID', context.rootState.accommodations);
                
                if(
                    context.rootState.accommodations.accommodations != null &&
                    context.rootState.accommodations.accommodations.length > 0
                    ){

                    let firstAccommodation = context.rootState.accommodations.accommodations[0] ; 
                    
                    context.rootState.app.settings.accommodation_id = firstAccommodation.id ;

                }else{
                    this.commit('app/set_error', i18n.t('Please select an accommodation')) ;
                    return ;
                }

            } 

            // if both the arrival and departure day are null set current date + week
            if(!isValidArrivalDate(context.rootState.app.settings) && !isValidDepartureDate(context.rootState.app.settings)){
                this.commit('app/set_arrival', dayjs().format("YYYY-MM-DD"));
                this.commit('app/set_departure', dayjs().add(context.rootState.app.settings.price_calculation.default_nr_of_nights, "day").format("YYYY-MM-DD"));
            }

            // check for arrival date
            if(!isValidArrivalDate(context.rootState.app.settings)){
                this.commit('app/set_error', i18n.t('Please select an arrival date')) ;
                return ;
            }  
            
            // check for departure date
            if(!isValidDepartureDate(context.rootState.app.settings)){
                this.commit('app/set_error', i18n.t('Please select an departure date')) ;
                return ;
            }

            this.commit('app/set_error', null) ;
            state.isLoading = true ;

            let priceCalculation = new price_calculationApi(context.rootState.app.settings) ;             

            if(
                context.rootState.priceCalculation.ageTables && 
                context.rootState.priceCalculation.ageTables !== null
            ){

                
                let tmp_age_tables = context.rootState.priceCalculation.ageTables ;

                priceCalculation.set_age_tables(tmp_age_tables) ;
                
            }

            if(
                context.rootState.priceCalculation.priceCalculation && 
                context.rootState.priceCalculation.priceCalculation.calculation_id
            ){
                 
                // if there is a price calculation id, send it with the request
                priceCalculation.set_draft_id(context.rootState.priceCalculation.priceCalculation.calculation_draft_id);
                priceCalculation.set_calculation_id(context.rootState.priceCalculation.priceCalculation.calculation_id);
                
            }

            // console.log('priceCalculation before,', context.rootState.app.settings); 

            state.priceCalculation = null ;
            
            priceCalculation.get()
            .then((result) => {

                this.commit('priceCalculation/set', result);
                let newAgeTables = [];

                result.age_table_data.forEach(table_data => {
                    // console.log(state.ageTables.filter(table => table.id == table_data.id), "bla")
                    let tableToUpdate = state.ageTables.filter(table => table.id == table_data.id)[0]
                    tableToUpdate.count = table_data.count;
                    newAgeTables.push(tableToUpdate)
                })

                this.isLoading = false ;

            }) ;

        } catch (err) {

            this.isLoading = false ;
            this.commit('app/set_error', err) ;
            
        }
        
    },

    updateAgeTable(context, data){
        if(!context.rootState.priceCalculation.priceCalculation){
            return ; 
        }


        let tmp_ages_tables = context.rootState.priceCalculation.priceCalculation.age_tables ;

        tmp_ages_tables.filter((age_table) => {

            if(age_table.id == data.age_table.id){
                age_table.count = data.count ;
            }

        });

        let persons = 0 ;
        
        tmp_ages_tables.forEach((age_table) => {
            persons = persons + age_table.count 
        });


        this.commit('app/set_persons', persons) ;
        this.commit('priceCalculation/set_age_tables', tmp_ages_tables) ;

    },

    addOption(context, option) {

        try {

            if(state.isLoading){
                console.log('priceCalculation isLoading 2') ;
                return ;
            }

            state.isLoading = true ;

            let priceCalculation = new price_calculationApi(context.rootState.app.settings) ; 

            priceCalculation.set_option_id(option.id);
            priceCalculation.set_option_amount(option.selected_amount);

            priceCalculation.set_draft_id(state.priceCalculation.calculation_draft_id);
            priceCalculation.set_calculation_id(state.priceCalculation.calculation_id);

            priceCalculation.add_option()
            .then((result) => {

                this.commit('priceCalculation/set', result) ;
                state.isLoading = false ;

            }) ;

        } catch (err) {

            state.isLoading = false ;
            console.error('doPriceCalculation', err) ;
            this.commit('app/set_error', err) ;
            
        }
        
    },

    addDiscountCode(context, code) {
        if(!code || typeof code !== "string"){
            throw new Error("No discount code present, or in incorrect format!")
        }

        const PriceCalculation = new price_calculationApi(context.rootState.app.settings);
        PriceCalculation.set_endpoint("/price_calculation/add_promocode")
        PriceCalculation.set_calculation_id(state.priceCalculation.calculation_id)
        PriceCalculation.set_draft_id(state.priceCalculation.calculation_draft_id)
        PriceCalculation.set_accommodation_id(state.priceCalculation.accommodation_id)

        //Temp data needed for successfull request
        // PriceCalculation.set_
        
        PriceCalculation.addDiscountCode(code)
        .then(response => {
            this.commit("priceCalculation/set", response.data)
        })
    },

    addDiscountCard(context, code) {
        if(!code || typeof code !== "string"){
            throw new Error("No discount code present, or in incorrect format!");
        }

        try {
            const PriceCalculation = new price_calculationApi(context.rootState.app.settings);
            PriceCalculation.set_calculation_id(state.priceCalculation.calculation_id)
            PriceCalculation.set_draft_id(state.priceCalculation.calculation_draft_id)
            PriceCalculation.set_accommodation_id(state.priceCalculation.accommodation_id)
            PriceCalculation.set_discount_card(code);

            this.isLoading = true;

            PriceCalculation.get().then(response => {
                this.commit('priceCalculation/set', response);
                this.isLoading = false;

            })

        } catch (error){
            this.commit("app/set_error", error)
            this.isLoading = false;
        }
    }
}

const isValidArrivalDate = (settings) => {

    if(!settings.arrival){
        return false ; 
    }

    let arrivalDayJs = dayjs(settings.arrival).unix();

    if((dayjs().unix() - 86400) > arrivalDayJs){
        return false ;
    }

    return true ;

}

const isValidDepartureDate = (settings) => {
    
    if(!settings.departure){
        return false ; 
    }

    let departureDayJs = dayjs(settings.departure).unix();

    if(dayjs().unix() > departureDayJs){
        return false ;
    }

    return true ;
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
