import { i18n } from "../../plugins/i18n.js";
import dayjs from "dayjs";

// initial state
const state = {
    isLoading: null,

    settings: {
        public_key : null,
        language : null,
        admin_id : null,
        channel_id : null,
        birth_tables : null,
        age_tables: null,
        arrival: null,
        departure: null,
        accommodation_id : null,
        api_endpoint: 'https://api.camping.care/v2',
        nights: 0,
        persons: 0,
        book_url: null,
        accommodation_url: null,
        style_url: null,
        succes_url: null,
        redirect_enabled : true,
        
        price_calculation: {
            default_nr_of_nights: 7,
            get_discounts_price: true,
            get_taxes_price: true,
            get_guests_price: true,
            get_required_options_price: true,
            get_options : true
        }
        
    },
    error: null
};

// getters
const getters = {
    settings(state) {
        return state.settings ;
    },
    error(state) {
        return state.error ;
    },
};

const mutations = {

    set_arrival(state, arrival) {
        
        //console.log('arrival', arrival);
        
        // if(arrival == null){
        //     arrival = dayjs().format("YYYY-MM-DD")
        // }

        state.settings.arrival = arrival ;

        if(state.settings.arrival){
            state.settings.nights = dayjs(state.settings.departure).diff(state.settings.arrival, 'day') ;
        }else{
            state.settings.nights = 0 ;
        }
        
        // this.$emit("selectedDays", {
        //     arrival: state.settings.arrival,
        //     departure: state.settings.departure,
        //     nights: state.settings.nights
        // }) ;

    },
    set_departure(state, departure) {


        //console.log('departure', departure);

        // if(departure == null) {
        //     departure = dayjs().add(4, "day").format("YYYY-MM-DD")
        // }

        // if(dayjs(state.settings.arrival) > dayjs(departure)){
        //     state.settings.arrival = dayjs().format("YYYY-MM-DD")
        // }

        state.settings.departure = departure ;

        if(state.settings.departure){
            state.settings.nights = dayjs(state.settings.departure).diff(state.settings.arrival, 'day') ;
        }else{
            state.settings.nights = 0 ;
        }

    },
    set_accommodation_id(state, accommodation_id) {
        state.settings.accommodation_id = accommodation_id ;
    },
    set_birth_tables(state, birth_tables) {
        state.settings.birth_tables = birth_tables ;
    },
    set_age_tables(state, age_tables) {
        state.settings.age_tables = age_tables ;
    },
    set_admin_id(state, admin_id) {
        state.settings.admin_id = admin_id ;
    },
    set_public_key(state, public_key) {
        state.settings.public_key = public_key ;
    },
    set_channel_id(state, channel_id) {
        state.settings.channel_id = channel_id ;
    },
    set_book_url(state, book_url) {
        state.settings.book_url = book_url ;
    },
    set_succes_url(state, succes_url) {
        state.settings.succes_url = succes_url ;
    },
    set_accommodation_url(state, accommodation_url) {
        state.settings.accommodation_url = accommodation_url ;
    },
    set_style_url(state, style_url) {
        state.settings.style_url = style_url ;
    },
    set_persons(state, persons){
        state.settings.persons = persons ;
    },
    set_language(state, language) {
        if(language){
            i18n.locale = language ; 
            state.settings.language = language ;
        } else {
            i18n.locale = 'en'
            state.settings.language = "en" ;
        }

    },
    set_api_endpoint(state, api_endpoint) {
        state.settings.api_endpoint = api_endpoint ;
    },
    set_error(state, error) {
        state.error = error ;
    }    

};

// actions
const actions = {

    // this gets the administration and will save it into the store.
    init(settings = null) {
        
        try {

            if (process.env.NODE_ENV !== "development" &&
                typeof cc_settings !== "undefined" && // eslint-disable-next-line
                cc_settings !== null // eslint-disable-next-line
            ){
                // eslint-disable-next-line
                settings = cc_settings ;


            }else if(process.env.NODE_ENV === "development"){

                // settings = settings;
                settings.admin_id = parseInt(process.env.VUE_APP_ADMIN_ID) ;
                settings.public_key = process.env.VUE_APP_PUBLIC_KEY ;
                settings.channel_id = parseInt(process.env.VUE_APP_CHANNEL_ID) ;
                settings.language = process.env.VUE_APP_LANGUAGE ;
                settings.accommodation_id = parseInt(process.env.VUE_APP_ACCOMMODATION_ID) ;
                settings.api_endpoint = process.env.VUE_APP_API_ENDPOINT ;

                settings.arrival = process.env.VUE_APP_ARRIVAL ;
                settings.departure = process.env.VUE_APP_DEPARTURE ;
                // settings.birth_tables = [{birthdate:"2013-08-02", count:"1"}, {birthdate:"1977-03-07", count:"1"}];
                // settings.age_tables = [{
                //     id:4365,
                //     count: 3
                // },{
                //     id:4366,
                //     count: 0
                // },{
                //     id:4367,
                //     count: 1
                // }]
                
                settings.succes_url = process.env.VUE_APP_SUCCES_URL ;

                settings.book_url = 'https://guus-testpark.camping.care' ;
                settings.accommodation_url = 'http://localhost:8080/testAccoUrl' ;

                settings.style_url = 'https://storage.googleapis.com/campingcare-static/klanten/leadingcampings/booking_engine.css' ;
                
            }else{
                console.error("No settings found");
                throw "No settings found";
            }
            
            this.commit('app/set_admin_id', settings.admin_id) ;
            this.commit('app/set_public_key', settings.public_key) ;
            this.commit('app/set_channel_id', settings.channel_id) ;
            this.commit('app/set_language', settings.language) ;
            
            this.commit('app/set_admin_id', settings.admin_id) ;

            this.commit('app/set_arrival', settings.arrival) ;
            this.commit('app/set_departure', settings.departure) ;
            this.commit('app/set_accommodation_id', settings.accommodation_id) ;

            this.commit('app/set_succes_url', settings.succes_url) ;

            this.commit('app/set_book_url', settings.book_url) ;
            this.commit('app/set_accommodation_url', settings.accommodation_url) ;
            this.commit('app/set_style_url', settings.style_url) ;
            
            if(settings.birth_tables){ this.commit('app/set_birth_tables', settings.birth_tables) ; }
            if(settings.age_tables){ this.commit('app/set_age_tables', settings.age_tables) ; }
            if(settings.api_endpoint){ this.commit('app/set_api_endpoint', settings.api_endpoint) ; }

            this.dispatch('administration/init') ;
            

        } catch (err) {

            console.error('init app', err) ;
            
        }
        
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
