// import { i18n } from "./plugins/i18n" ;
import moment from "moment";
import store from "./store";

/* eslint-disable */

class helpers {

    get_api_endpoint(endpoint, params) {

        let settings = store.state.app.settings ;

        return (
            settings.api_endpoint +
            endpoint +
            "?public_key=" +
            settings.public_key +
            "&lang=" +
            settings.language +
            "&admin_id=" +
            settings.admin_id +
            "&channel_id=" +
            settings.channel_id +
            params
        );
    }

    get_date(date) {
        return moment(date).format("DD-MM-YYYY") ;
    }

    get_lang() {

        let locale = store.state.app.settings.language ;

        if (!locale || typeof locale === undefined) {
            return "en";
        }

        return locale;
    }

    get_money(price) {

        let administration = store.state.administration.administration ;
        
        if(administration == null){
            return price ;
        }

        let settings = store.state.app.settings ;

        /**
         *
         * price_notation
         * int, eu, int_after, eu_after
         */

        // if (price >= 0) {
        let currency = 'EUR' ;

        if(administration.meta != null && administration.meta.currency != null){
            currency = administration.meta.currency ;
        }

        if(typeof settings.currency != 'undefined' && settings.currency){
            currency = settings.currency ;
        }

        let price_notation = administration.meta.price_notation;
        let currencySymbol = this.get_currency_symbol(currency) ;
        let seperator = price_notation === "eu" || price_notation === "eu_after" ? "," : ".";

        if (price % 1 != 0) {

            price = price.toString();
            //Number with decimals
            let commaIndex = 0;

            if (price_notation == "eu" || price_notation == "eu_after") {
                price = price.replace(".", ",");
                commaIndex = price.indexOf(",") + 1;
            } else {
                // price = price.toString();
                commaIndex = price.indexOf(".") + 1;
            }

            let stringLength = price.length;

            /** Add 0 when there is one decimal */
            if (stringLength - commaIndex == 1) {
                price += "0";
            } else if (stringLength - commaIndex > 2) {
                let formatted = price.split(seperator)[1].substr(0, 2);
                /** Round prices if needed here **/

                price = price.split(seperator)[0] + seperator + formatted;
            }

            if (price_notation == "int" || price_notation == "eu") {
                //removed space
                return currencySymbol + "" + price;
            }
            return price + " " + currencySymbol;
        } else {
            //Number without decimals
            if (price_notation == "int" || price_notation == "eu") {
                return currencySymbol + price + seperator + "00 ";
            }
            return price + seperator + "00 " + currencySymbol;
        }
        // }
    }

    get_currency_symbol(currency) {

        if(currency == null){
            return '€';
        }

        currency = currency.toLowerCase() ;

        if(currency == 'usd'){
            return '$';
        }else if(currency == 'gbp'){
            return '£';
        }else if(currency == 'eur'){
            return '€';
        }

        return currency.toUpperCase() ;

    }

    get_var(type, prop, priceCalculation) {
        
        // check if there is a property given
        // if so, always use this type
        if (typeof prop !== "undefined" && prop !== null && prop) {
            return prop;
        }

        // check if the type is given in the price calculation
        if (
            typeof priceCalculation !== "undefined" &&
            priceCalculation !== null &&
            priceCalculation[type]
        ) {
            return priceCalculation[type];
        }

        // check if the type is given in the global variable

        console.log('get var before settings', cc_settings);        

        if (
            typeof cc_settings !== "undefined" && // eslint-disable-next-line
            cc_settings !== null && // eslint-disable-next-line
            cc_settings[type]
        ) {

            return cc_settings[type];
        }

        return null ;

        // throw "No " +
        //     type +
        //     " found, please define the date in the properties of the component";

    }

    get_admin_id(type, prop, priceCalculation) {

        if (process.env.NODE_ENV === "development") {

            if(prop){
                return prop ;
            }else{
                return process.env.VUE_APP_ADMIN_ID ;
            }

        } else {
            return this.get_var("arrival", prop, priceCalculation);
        }

    }

    get_arrival(prop, priceCalculation) {

        if (process.env.NODE_ENV === "development") {

            if(prop){
                return prop ;
            }else{
                return moment().format("YYYY-MM-DD") ;
            }

        } else {
            return this.get_var("arrival", prop, priceCalculation);
        }

    }

    get_departure(prop, priceCalculation) {

        if (process.env.NODE_ENV === "development") {

            if(prop){
                return prop ;
            }else{
                return moment().add(60, 'days').format("YYYY-MM-DD") ;
            }

        } else {
            return this.get_var("departure", prop, priceCalculation);
        }

    }

    get_accommodation_id(prop, priceCalculation) {

        if (process.env.NODE_ENV === "development") {
            return process.env.VUE_APP_ACCOMMODATION_ID;
        } else {
            return this.get_var("accommodation_id", prop, priceCalculation);
        }
        
    }

    get_birth_tables(prop, priceCalculation) {

        let birth_tables = [];

        if (typeof prop === "string" || prop instanceof String) {
            birth_tables = JSON.parse(prop);
        } else {
            birth_tables = prop;
        }

        if (process.env.NODE_ENV === "development") {
            birth_tables =
                '[{"birthdate":"2013-08-02", "count":"1"}, {"birthdate":"1977-03-07", "count":"1"}]';
            return birth_tables;
        } else {
            return this.get_var("birth_tables", prop, priceCalculation);
        }

    }

    get_settings() {
        
        // check if settings have a admin_id and public_key as a minimum
        if (process.env.NODE_ENV === "development") {

            let settings = {};

            // settings.admin_id = 704 ;
            // settings.public_key = "y7pHl3TXYAOlSe8w5Y266qzk" ;

            settings.admin_id = process.env.VUE_APP_ADMIN_ID ;
            settings.public_key = process.env.VUE_APP_PUBLIC_KEY ;

            settings.channel_id = 1 ;
            settings.language = 'nl' ;

            return settings ;

        }

        // check if the type is given in the global variable
        if (
            typeof cc_settings !== "undefined" &&
            cc_settings !== null &&
            typeof cc_settings === "object"
        ) {
            // eslint-disable-next-line
            return cc_settings;
        }

        console.error('No cc_settings found, please define a javascript object with cc_settings');

        throw "No cc_settings found, please define a javascript object with cc_settings ";
    }

    get_static_booking_url(administration){

        if(this.is_url("https://"+administration.slug)){
            return "https://"+administration.slug ;
        }else{
            return "https://"+administration.slug+".camping.care" ;
        }

    }

    is_url(str) {

        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);

    }

    get_button_url(type){

        let settings = store.state.app.settings ;
        let ageTables = store.state.priceCalculation.ageTables ;
        let administration = store.state.administration.administration ;
        
        let params = "?bc_arrival="+settings.arrival ;
        params = params + "&bc_departure="+settings.departure ;
        params = params + "&admin_id="+settings.admin_id ;

        if (ageTables) {
            
            let age_tables_limited = [];

            ageTables.forEach((age_table) => {
                
                let tmpAgeTable = {
                    id: parseInt(age_table.id),
                    count: parseInt(age_table.count)
                }

                age_tables_limited.push(tmpAgeTable);

            }) ;

            params = params + "&bc_age_table_input="+encodeURI(JSON.stringify(age_tables_limited)) ;
        
        }

        let lang = 'en' ;

        if(settings.language){
            lang = settings.language ;
        }
        
        if(type == 'book' && settings.book_url){

            params = params + "&bc_lang="+lang ;

            if(settings.accommodation_id){
                params = params + "&bc_accommodation_id="+settings.accommodation_id ;     
            }

            return settings.book_url+params ; 

        }else if (type == 'accommodation' && settings.accommodation_url) {

            params = params + "&bc_lang="+lang ;

            if(settings.accommodation_id){
                params = params + "&bc_accommodation_id="+settings.accommodation_id ;     
            }

            return settings.accommodation_url+params ; 
            
        }else if (administration) {

            if(settings.accommodation_id){

                params = '/'+lang+'/detail/'+settings.accommodation_id+params ;
    
            }
            return this.get_static_booking_url(administration)+params ; 

        } else {

            return "https://www.camping.care" ;

        }

    }

    years() {
        let year = new Date().getFullYear();
        return Array.from(
            { length: year - 1900 },
            (value, index) => year - index
        );
    }


}

export default new helpers();
