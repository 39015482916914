import axios from "axios";
import helpers from "../../helpers";
import moment from "moment";

class places {

    constructor(){
        this.endpoint = "/places/available"
    }

    set_accommodation(id) {
        this.accommodation_id = id;
    }

    set_arrival(arrival) {
        this.arrival = arrival
    }

    set_departure(departure) {
        this.departure = departure;
    }

    // set_admin_id(id) {
    //     this.admin_id = id;
    // }

    get() {
        if (!this.arrival || !this.departure) {
            return;            
        }

        let params = "";

        params = params + "&arrival=" + moment(this.arrival).format("YYYY-MM-DD");
        params = params + "&departure=" + moment(this.departure).format("YYYY-MM-DD");

        if (this.accommodation_id) {
            params = params += "&accommodation_id=" + this.accommodation_id;
        }

        return axios.get(helpers.get_api_endpoint(this.endpoint, params))
        .then((response) => {
            
            return response.data;
        })
            // .catch((error) => {
            //     let errorMessage = error.response.data.error.message;
            //     console.log(error.response, "errorrr");
            //     return false;
            // });
    }
}

export default places;
