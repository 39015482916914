import axios from "axios";
import helpers from "../../helpers";

class accommodation {

    constructor() {
        this.endpoint = "/accommodations";
        this.accommodation_id = 0;
        this.media = false;
        this.admin_id = "";
        this.translations = true;
    }

    set_accommodation_id(accommodation_id) {
        this.accommodation_id = accommodation_id;
    }

    get_translations(value) {
        this.translations = value;
    }

    set_media(media) {
        this.get_media = media;
    }

    set_meta(meta) {
        this.get_meta = meta;
    }

    set_services(value) {
        this.get_services = value;
    }

    set_arrangements(value) {
        this.get_arrangements = value;
    }

    get() {

        let params = "";

        if (this.media) {
            params = params + "&get_media=true";
        }

        if (this.translations) {
            params = params + "&get_translations=" + this.translations;
        }

        if (this.get_meta) {
            params = params += "&get_meta=" + this.get_meta;
        }

        if (this.get_media) {
            params = params += "&get_media=" + this.get_media;
        }

        if (this.get_services) {
            params = params += "&get_services=" + this.get_services;
        }

        if (this.get_arrangements) {
            params = params += "&get_arrangements=" + this.get_arrangements;
        }

        // params = params;

        return axios({
            method: "get",
            url: helpers.get_api_endpoint(
                this.endpoint + "/" + this.accommodation_id,
                params
            )
        })
        .then(response => {

            console.log('GOT ACCO', response.data);
            return response.data ;

        })
        .catch(error => {

            console.log('err ACCO', error);
            throw "Error in get accommodation: "+ error.message ;
        });

    }
    
}

export default accommodation;
