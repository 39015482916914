const state = {
    fields: null
}

const getters = {
    getCustomFields(state){
        if(state.fields){
            return state.fields;
        }

        return null
    }
}

const actions = {

}

const mutations = {
    SET_FIELDS(state, fields){
        state.fields = fields;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}