import axios from "axios";
import helpers from "../../helpers.js";

const state = {
    formData: {
        coTravelersInformation: null,
    },
    invalidFields: null,
    country: null,
}

const getters = {

    getCoTravelersInformation(state){
        return state.formData.coTravelersInformation
    },

    getInvalidFields(state) {
        return state.invalidFields
    },

    getCountry(state) {
        return state.country.toLowerCase()
    }

}

const actions = {

    generatecoTravelersInformation({commit}, amount){

        let information = []

        while(information.length != amount) {

            // console.log(information.length, amount, "amount");
            information.push({
                first_name_co: null,
                last_name_co: null,
                birthDate: {
                    birth_day_co: null,
                    birth_month_co: null,
                    birth_year_co: null
                },
                id_number_co: null,
                country_origin_co: null
            })
            
        }

        commit('setCoTravelersInformation', information) ;

    },

    getCountry(){

        axios
        .get(helpers.get_api_endpoint('/contacts/current_country'))
        .then((response) => {

            state.country = response.data ;

        })

    },

}

const mutations = {
    setCoTravelersInformation(state, information){
        state.formData.coTravelersInformation = information;
    },

    setInvalidFields(state, fields) {
        state.invalidFields = fields;
    },

    setCountry(state, country){
        state.country = country;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}