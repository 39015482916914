import axios from "axios";
import helpers from "../../helpers.js";

class administration {

    constructor(public_key) {

        this.endpoint = "/administrations";
        this.public_key = public_key;

    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_admin_id(id) {
        this.admin_id = id;
    }

    set_age_tables(value) {
        this.age_tables = value;
    }

    set_discount_cards(value) {
        this.discount_cards = value;
    }

    get() {

        let params = "&get_meta=1";

        if (this.age_tables) {
            params = params + "&get_age_tables=" + this.age_tables;
        }

        if (this.discount_cards) {
            params = params + "&get_discount_cards=" + this.discount_cards;
        }

        if (this.endpoint) {
            this.endpoint = this.endpoint + "/" + this.admin_id ;
        }

        return axios
            .get(helpers.get_api_endpoint(this.endpoint, params))
            .then((response) => {

                return response.data ;
                
            })
            .catch((error) => {

                if(
                    typeof error.response != 'undefined' && 
                    typeof error.response.data != 'undefined' && 
                    typeof error.response.data.error != 'undefined'
                    ){

                    let errorMessage = error.response.data.error.message ;

                    throw errorMessage ;

                }

                throw error.message ;
                
            });
    }
}

export default administration;
