import axios from "axios";
// import moment from "moment";
import helpers from "../../helpers";

class price_calculation {

    get_options = true;

    constructor(data) {
        this.alterCalculation = false;
        this.endpoint = "/price_calculation";
        this.arrival = data.arrival ;
        this.departure = data.departure ;
        this.search_alternative = true;
        this.accommodation_id = data.accommodation_id ;
        this.admin_id = data.admin_id ;
        this.age_tables = data.age_tables ;
        this.birth_tables = data.birth_tables ;
        this.persons_amount = data.persons_amount ;
        this.settings = data ;
        this.calculation_id = data.calculation_id ? data.calculation_id : null;
        this.calculation_draft_id = data.calculation_draft_id ? data.calculation_draft_id : null
        
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    get_option_rows(status) {
        this.get_options = status;
    }

    set_draft_id(value) {
        this.calculation_draft_id = value;
    }

    set_calculation_id(value) {
        this.calculation_id = value;
    }

    set_option_amount(amount) {
        this.option_amount = amount;
    }

    set_option_id(id) {
        this.option_id = id;
    }

    set_age_tables(age_tables) {
        this.age_tables = age_tables;
    }

    set_discount_card(card_id) {
        this.discount_card = card_id;
    }

    set_arrangement_id(arrangement_id) {
        this.arrangement_id = arrangement_id;
    }

    set_accommodation_id(accommodation_id) {
        this.accommodation_id = accommodation_id;
    }

    set_amount(persons) {

        if (!Array.isArray(persons)) {
            this.persons_amount = persons.amount;
        } else {
            let persons_object = persons.map((person) => {
                return {
                    id: person.id,
                    count: person.amount,
                };
            });
            this.persons_amount = persons_object;
        }

    }

    alterCalculationData(){
        this.alterCalculation = true;
    }

    get() {

        let params = "";

        // if(!this.arrival){
        //     this.arrival = moment().format("YYYY-MM-DD");
        // }

        // if(!this.departure){
        //     this.departure = moment(moment().add(1, 'days')).format("YYYY-MM-DD") ;
        // }
        console.log("settings" , this.settings)

        if(this.alterCalculation){
            params = params + "&calculation_id=" + this.calculation_id;
            params = params + "&calculation_draft_id=" + this.calculation_draft_id;
        }

        params = params + "&arrival=" + this.arrival;
        params = params + "&departure=" + this.departure;
        params = params + "&accommodation_id=" + this.accommodation_id;

        // calculate extra prices
        params = params + "&get_options=" + this.settings.price_calculation.get_options
        params = params + "&get_discounts_price=" + this.settings.price_calculation.get_discounts_price
        params = params + "&get_required_options_price=" + this.settings.price_calculation.get_required_options_price;
        params = params + "&get_taxes_price=" + this.settings.price_calculation.get_taxes_price;
        params = params + "&get_guests_price=" + this.settings.price_calculation.get_guests_price;

        if (this.discount_card) {
            params = params + "&card_id=" + this.discount_card;
        }

        if (this.arrangement_id) {
            params = params + "&force_price_id=" + this.arrangement_id;
        }

        if(this.age_tables){
            
            params = params + "&age_tables=" + JSON.stringify(this.age_tables);

            this.persons_amount = 0 ;

            this.age_tables.forEach(age_table => {
                this.persons_amount = parseInt(age_table.count) + this.persons_amount ;
            });

            params = params + "&persons=" + this.persons_amount;

        }else if (this.persons_amount) {

            if (Array.isArray(this.persons_amount)) {
                params = params + "&age_tables=" + JSON.stringify(this.persons_amount);
            } else {
                params = params + "&persons=" + this.persons_amount;
            }

        }else if(this.birth_tables){
            params = params + "&birth_tables=" + JSON.stringify(this.birth_tables) ;
        }else{
            params = params + "&persons=2" ;
        }


        return axios
            .get(helpers.get_api_endpoint(this.endpoint, params))
            .then((response) => {

                return response.data ;

            })
            .catch((error) => {

                if(
                    typeof error.response != 'undefined' && 
                    typeof error.response.data != 'undefined'
                ){

                    let errorMessage = error.response.data.error.message;

                    throw errorMessage ;

                }

                throw error.message ;
                
            });
        
    }

    add_option() {

        if (!this.accommodation_id 
            || !this.calculation_draft_id 
            || !this.calculation_id 
            || this.option_amount === undefined 
            || !this.option_id) {
            throw new Error("Not all requirements are filled");
        }

        let args = {
            accommodation_id: this.accommodation_id,
            option_ids: [this.option_id],
            option_value: this.option_amount,
            calculation_id: this.calculation_id,
            calculation_draft_id: this.calculation_draft_id
        }
        // let params = "";

        // params = params + "&accommodation_id=" + this.accommodation_id;
        // params = params + "&option_ids=["+this.option_id+"]";
        // params = params + "&option_value="+this.option_amount;

        // params = params + "&calculation_id="+this.calculation_id;
        // params = params + "&calculation_draft_id="+this.calculation_draft_id;

        return axios
            .put(helpers.get_api_endpoint(this.endpoint+"/add_option"), args)
            .then((response) => {
                
                console.log('response add_option', response); 

                return response.data ;

                // price_calculation_data.accommodation_id = this.accommodation_id;
            })
            .catch((error) => {
                
                if(
                    typeof error.response != 'undefined' && 
                    typeof error.response.data != 'undefined'
                ){

                    let errorMessage = error.response.data.error.message;

                    throw errorMessage ;

                }

                throw error.message ;

            });
    }

    addDiscountCode(code){
        // console.log(this, code, "code");
        if(!this.calculation_draft_id || !this.calculation_id || !this.accommodation_id || !code){
            throw new Error("Requirements are not met!");
        }

        let params = `&calculation_draft_id=${this.calculation_draft_id}&calculation_id=${this.calculation_id}&accommodation_id=${this.accommodation_id}&code=${code}`
        
        if(this.arrival){
            params = params + `&arrival=${this.arrival}`
        }

        if(this.departure){
            params = params + `&departure=${this.departure}`
        }

        if(this.age_tables){
            
            console.log('age_tables sett', this.age_tables);
            params = params + "&age_tables=" + JSON.stringify(this.age_tables);

            this.persons_amount = 0 ;

            this.age_tables.forEach(age_table => {
                this.persons_amount = parseInt(age_table.count) + this.persons_amount ;
            });

            params = params + "&persons=" + this.persons_amount;

        }else if (this.persons_amount) {

            if (Array.isArray(this.persons_amount)) {
                params = params + "&age_tables=" + JSON.stringify(this.persons_amount);
            } else {
                params = params + "&persons=" + this.persons_amount;
            }

        }else if(this.birth_tables){
            params = params + "&birth_tables=" + JSON.stringify(this.birth_tables) ;
        }else{
            params = params + "&persons=2" ;
        }


        return axios.get(helpers.get_api_endpoint(this.endpoint, params))
        .then(response => {
            console.log(response)
            return response
        })
        .catch(error => {
            throw new Error(error.message);
        })
    }
}

export default price_calculation;
