import class_places from "../../api/places/places"
import class_place from "../../api/places/place"

const state = {
    //Places for storing places from the api
    place: null,
    places: null,

    placesError: null,
    loadingPlaces: false,
    selectedPlace: null, //--> Place for storing selected Place
}

const getters = {

}

const mutations = {
    set(state, places){
        state.places = places
        state.loadingPlaces = false
    },

    setSelectedPlace(state, place){
        state.selectedPlace = place
    },

    setError(state, error){
        state.placesError = error;

        if(state.loadingPlaces){
            state.loadingPlaces = false;
        }
    }
}

const actions = {
    fetch(context){
        try{
            if(state.places){
                console.log("Places already exists");
                return;
            }

            if(state.loadingPlaces){
                console.log("Places are already being fetched!");
                return;
            }

            state.loadingPlaces = true

            const PlacesAPI = new class_places();
            PlacesAPI.set_accommodation(context.rootState.app.settings.accommodation_id)
            PlacesAPI.set_arrival(context.rootState.app.settings.arrival)
            PlacesAPI.set_departure(context.rootState.app.settings.departure);
            
            return PlacesAPI.get().then(response => {
                this.commit('places/set', response);
            })
            .catch(error => {
                console.log("[ccMap] - Error in fetching available places: ", error)
                this.commit('places/setError', error.message)
            })




        } catch(error) {
            console.log(error)
            this.commit("places/setError", error.message)
        }
    },

    //Get single
    get(context, place_id){
        console.log(place_id);
        if(state.place){
            console.log("Place already fetched!");
            return 
        }

        if(!place_id){
            console.log("No place id present");
            state.placesError = "No place_id in get place!"
            return;
        }

        const Place = new class_place();
        return Place.get(place_id)
        .then(response => {
            this.commit('places/set', response)
            return response
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}