import axios from "axios";
import helpers from "../../helpers.js";

class Accommodations {

    constructor() {
        this.endpoint = "/accommodations";
        this.accommodation_id = 0;
        this.media = true;
        this.admin_id = "";
        this.set_translations = true
    }

    get_translations(value) {
        this.translations = value;
    }

    set_media(media) {
        this.get_media = media;
    }

    set_meta(meta) {
        this.get_meta = meta;
    }

    set_services(value) {
        this.get_services = value;
    }

    set_arrangements(value) {
        this.get_arrangements = value;
    }
    set_public_key(value) {
        this.public_key = value;
    }

    get() {

        let params = "";

        if (this.acco_type != "") {
            params = "&acco_type=" + this.acco_type;
        }

        if (this.get_meta) {
            params = params += "&get_meta=" + this.get_meta;
        }

        if (this.get_media) {
            params = params += "&get_media=" + this.get_media;
        }

        if (this.get_translations) {
            params = params += "&get_translations=" + this.set_translations;
        }

        if (this.get_services) {
            params = params += "&get_services=" + this.get_services;
        }

        if (this.get_arrangements) {
            params = params += "&get_arrangements=" + this.get_arrangements;
        }

        // params = params += "&gaet_media=true";

        return axios
            .get(helpers.get_api_endpoint( this.endpoint, params))
            .then((response) => {

                return response.data ;

                // let new_accommodation_list = [];

                // response.data.forEach((accommodation) => {

                //     accommodation.images = [];

                //     if ("media" in accommodation && accommodation.media.length > 0) {
                //         accommodation.media.forEach(function(media) {
                //             let media_data = JSON.parse(media.data);

                //             let tmp_image = {
                //                 src: media_data.public_url,
                //                 thumbnail: media.thumb,
                //                 w: media_data.width,
                //                 h: media_data.height,
                //                 alt: accommodation.name,
                //             };

                //             accommodation.images.push(tmp_image);
                //         });
                //     }
                    
                //     if(accommodation.meta && typeof accommodation.meta != "undefined"){
                //         accommodation.meta.persons_min = parseInt(accommodation.meta.persons_min);
                //         accommodation.meta.persons_max = parseInt(accommodation.meta.persons_max);
                //     }

                //     new_accommodation_list.push(accommodation);

                // });

                // console.log('new_accommodation_list');
                // console.log(new_accommodation_list);

                // return new_accommodation_list ;

            })
            .catch((error) => {
                console.log(error, "error");

            });
    }
}

export default Accommodations;