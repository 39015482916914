import axios from "axios";
import helpers from "../../helpers.js";

class place {

    get(place_id) {

        return axios
            .get(helpers.get_api_endpoint('/places/'+place_id, ""))
            .then((response) => {

                console.log('palce id');
                console.log(response);

                return response.data ;

            })
            .catch((error) => {

                console.log('response error');
                console.log(error);

                if(
                    typeof error != 'undefined' && 
                    typeof error.response != 'undefined' && 
                    typeof error.response.data != 'undefined' && 
                    typeof error.response.data.error != 'undefined'
                    ){

                    let errorMessage = error.response.data.error.message ;

                    throw errorMessage ;

                }

                console.error('place.get error', error);

                throw error.message ;
                
            });
    }
}

export default place;
