import administrationApi from "../../api/administrations/administration";
import dayjs from "dayjs";

const state = {
    isLoading: false,
    administration: null,
    mapData: null,
    mapPoints: null,
    persons: null,
    administrationError: null,
    initialAgeTables: true
};

// getters
const getters = {

    get(state) {
        return state.administration ;
    },
    get_persons(state) {
        return state.persons ;
    },

    getAgeTables(state){
        // return ageTables
        return state.administration.age_tables
    }

};

const mutations = {

    set(state, administration) {

        if(administration.meta && "map_image" in administration.meta){

            if (typeof administration.meta.map_image === "string" || administration.meta.map_image instanceof String) {
                state.mapData = JSON.parse(administration.meta.map_image);
            }else{
                state.mapData = administration.meta.map_image;
            }

            
        }

        if(administration.meta && "map_data" in administration.meta){

            if (typeof administration.meta.map_data === "string" || administration.meta.map_data instanceof String) {
                state.mapPoints = JSON.parse(administration.meta.map_data);
            }else{
                state.mapPoints = administration.meta.map_data;
            }
        }

        state.administration = administration ;
        state.isLoading = false ;
        this.dispatch('priceCalculation/get');

    },

    set_persons(state, persons) {

        state.persons = persons ;

    },

    set_age_tables(state, age_tables) {
        state.administration.age_tables = age_tables ;
    },

    setError(state, error){
        state.administrationError = error;

        if(state.isLoading){
            state.isLoading = false;
        }
    }

};

// actions
const actions = {

    // this gets the administration and will save it into the store.
    init(context) {
        
        try {
            if(state.isLoading){
                // console.log('Administration isLoading') ;
                return ;
            }

            state.isLoading = true ;

            if(state.administration){
                // console.log('Administration already loaded', state.administration) ;
                state.isLoading = false;
                return ;
            }

            let administration = new administrationApi();

            administration.set_admin_id(context.rootState.app.settings.admin_id);
            administration.set_age_tables(true);
            administration.set_discount_cards(true);

            administration.get().then(result => {

                // console.log('result.meta.online_checkin', result.meta.online_checkin);
                if(result.id == null){
                    console.log("No id found for this administration, OTA park?")
                    return; 
                }

                if(
                    result.meta != null &&
                    "online_checkin" in result.meta && 
                    result.meta.online_checkin
                ){

                    if (typeof result.meta.online_checkin === "string" || result.meta.online_checkin instanceof String) {
                        result.meta.online_checkin = JSON.parse(result.meta.online_checkin)
                    }

                    //result.meta.online_checkin = JSON.parse(result.meta.online_checkin)

                }

                if(result.age_tables && !("count" in result.age_tables[0])){
                    result.age_tables.forEach(table => {
                        if(!context.rootState.app.settings.birth_tables){
                            table.count = table.priority == "1" ? 2 : 0;
                        } else {
                            table.count = 0;
                        }
                    })                    
                }

                if(context.rootState.app.settings.birth_tables){
                    result.age_tables = convertBirthTables(result.age_tables, context.rootState.app.settings.birth_tables) ;
                }
                
                // commit the administration and save it into the store
                this.commit('administration/set', result) ;
                this.commit("bookForm/setCountry", result.meta.country)
                this.commit("priceCalculation/set_age_tables", result.age_tables);
                
            })
            .catch(error => {
                console.log("[administration] - Error in fetching administration: ", error)
                this.commit('administration/setError', error)
            })

        } catch (err) {

            state.isLoading = false ;
            console.error('get_administration_from_api', err) ;
            
        }
        
    },

    updateAgeTable(context, data){

        let tmp_ages_tables = context.rootState.administration.administration.age_tables ;

        tmp_ages_tables.filter((age_table) => {

            if(age_table.id == data.age_table.id){
                age_table.count = data.count ;
            }

        }) ;

        this.commit('administration/set_age_tables', tmp_ages_tables) ;

        this.dispatch('priceCalculation/updateAgeTable', data) ;

    },

};

function convertBirthTables(age_tables, birth_tables){
    
    // convert birth tables to age tables
    if(
        birth_tables && 
        birth_tables.length > 0
    ){

        // first, set all age tables to 0
        age_tables.forEach(age_table => {
            age_table.amount = 0 ;
        })

        birth_tables.forEach(birth_table => {

            let age = dayjs().diff(birth_table.birthdate, 'year') ;

            age_tables.filter((age_table) => {
                age_table.age_from = parseInt(age_table.age_from); 
                age_table.age_to = parseInt(age_table.age_to); 
                
                age_table.count = parseInt(age_table.count); 
                birth_table.count = parseInt(birth_table.count); 

                if(isNaN(age_table.count)){ age_table.count = 0  }
                if(isNaN(birth_table.count)){ birth_table.count = 0  }

                if(
                    age >= age_table.age_from && 
                    age < age_table.age_to){

                    age_table.count = age_table.count + birth_table.count ;

                }else if(
                    age >= age_table.age_from && 
                    isNaN(age_table.age_to)
                ){
                    
                    age_table.count = age_table.count + birth_table.count ;

                }else if(
                    isNaN(age_table.age_from) &&
                    age >= age_table.age_to  
                ){
                    
                    age_table.count = age_table.count + birth_table.count ;

                }


            });

        });

        return age_tables ;

    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
