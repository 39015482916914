<template>
    
    <div v-if="iframeLink">

        <iframe ref="iframeTarget"
        id="iframeTarget"
        @load="iframeStyles" 
        style="width:100%; height:900px;" 
        :src="iframeLink"></iframe>

        <!-- <p>{{ iframeLink }}</p>
        <pre>{{ this.settings }}</pre>  -->

    </div>

</template>

<style>

body {
    margin: 0px 0px !important;
}

#iframeTarget {
    margin: 0px 0px;
    border: none ;
}

</style>

<script>

import { mapGetters } from "vuex";
import store from '../store'

export default {
    store,
    computed: {

        ...mapGetters({
            settings: "app/settings"
        }),

        iframeLink() {

            let language = 'en';
            let channel_id = 1 ;

            if(store.state.app.settings.admin_id == null){
                return null ;
            }

            if(store.state.app.settings.language != null){
                language = store.state.app.settings.language ;
            }

            if(store.state.app.settings.channel_id != null){
                channel_id = store.state.app.settings.channel_id ;
            }

            return "https://booking.camping.care/"+store.state.app.settings.admin_id+"?language="+language+"&channel_id="+channel_id ;

        }

    },

    methods: {
        iframeStyles() {
            
            console.debug('this.iframeDiv.style.height', this.$refs.iframeTarget.contentWindow) ;
            console.debug('.document.body.scrollHeight', this.$refs.iframeTarget.contentWindow.document.body.scrollHeight) ;
            // div.style.height = div.contentWindow.document.body.scrollHeight + 'px';
            
        }
    },
    created() {

        store.dispatch("app/init");

    },

        

        // div.onload = function() {
        //     div.style.height = div.contentWindow.document.body.scrollHeight + 'px';
        //     console.debug('div.style.height', div.style.height) ;
        // }

    //     setTimeout(() => {
    //         this.iframeDiv.style.height = this.iframeDiv.contentWindow.document.body.scrollHeight + 'px';
    //         console.debug('this.iframeDiv.style.height', this.iframeDiv.style.height) ;
    //     }, 5000) ;

    //     this.iframeDiv.style.height = this.iframeDiv.contentWindow.document.body.scrollHeight + 'px';
    //     console.debug('this.iframeDiv.style.height', this.iframeDiv.style.height) ;
        
    // },
    // data: {
    //     iframeDiv: document.getElementById('iframeTarget')
    // }
    
    // data() {

    //     return {
    //     };
    // },

    

};
</script>