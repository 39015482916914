import Vue from "vue";
import VueI18n from "vue-i18n";
import bookingengine_en from "../locale/cc-bookingengine_en.json";
import bookingengine_nl from "../locale/cc-bookingengine_nl.json";
import bookingengine_it from "../locale/cc-bookingengine_it.json";
import bookingengine_de from "../locale/cc-bookingengine_de.json";
import bookingengine_fr from "../locale/cc-bookingengine_fr.json";

// console.log('VueI18nVue start') ;

console.log('VueI18nVue', Vue) ;
Vue.use(VueI18n);

const messages = {
    en: bookingengine_en,
    nl: bookingengine_nl,
    it: bookingengine_it,
    de: bookingengine_de,
    fr: bookingengine_fr
};

export const i18n = new VueI18n({
    locale: 'de', //Locale
    fallbackLocale: "en", //set fallback locale,
    messages,
});