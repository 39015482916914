import class_accommodation from "../../api/accommodations/accommodation";
import { i18n } from "../../plugins/i18n"

const state = {
    accommodation: null,
    arrangements: null,
    loading: false,
}

const getters = {
    getAccommodationName(state){
        const locale = i18n.locale;
        const availableLocales = Object.keys(state.accommodation.translations)

        if(availableLocales.includes(locale) && state.accommodation.translations[locale].name){
            return state.accommodation.translations[locale].name;
        }

        return i18n.t("No name found for this accommodation");
    },

    getAccommodationDescription(){
        const locale = i18n.locale;
        const availableLocales = Object.keys(state.accommodation.translations)

        if(availableLocales.includes(locale) && state.accommodation.translations[locale].description){
            return state.accommodation.translations[locale].description;
        }

        return i18n.t("No description found for this accommodation");
    }
}

const actions = {

    fetch(context, options){

        try{
            
            if(state.accommodation){
                console.log("Accommodation already exists");
                throw "Accommodation already exists";
                // return;
            }

            if(state.loading){
                console.log("Accommodation is already being fetched!");
                throw "Accommodation is already being fetched!"
            }

            state.loading = true;
            
            const Accommodation = new class_accommodation();
            Accommodation.set_accommodation_id(context.rootState.app.settings.accommodation_id);
            if(options.getMeta == true) Accommodation.set_meta(true);
            if(options.getMedia == true) Accommodation.set_media(true);
            if(options.getServices == true) Accommodation.set_services(true);
            if(options.getArrangements == true) Accommodation.set_arrangements(true);
            if(options.getTranslations == true) Accommodation.get_translations(true);

            Accommodation.get()
            .then(response => {
                if("media" in response && response.media.length > 0){

                    let accommodationImages = [];

                    response.media.forEach(image => {
                        let mediaData = [];

                        if (typeof image.data === "string" || image.data instanceof String) {
                            mediaData = JSON.parse(image.data);
                        } else {
                            mediaData = image.data;
                        }

                        accommodationImages.push({
                            src: mediaData.public_url,
                            thumbnail: image.thumb,
                            w: mediaData.width,
                            h: mediaData.height
                        })
                    });

                    response.images = accommodationImages;
                }

                this.commit("accommodation/set", response)
            })


        } catch(error){
            console.log(error)
        }
    }
}

const mutations = {
    set(state, accommodation){
        if(accommodation){
            state.accommodation = accommodation;
            state.loading = false;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}