import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import administration from "./modules/administration";
import priceCalculation from "./modules/priceCalculation";
import accommodation from "./modules/accommodation";
import accommodations from "./modules/accommodations"
import places from "./modules/places"
import bookForm from "./modules/bookForm"
import customFields from "./modules/customFields"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app: app,
        administration: administration,
        priceCalculation: priceCalculation,
        accommodation: accommodation,
        accommodations: accommodations,
        places: places,
        bookForm: bookForm,
        customFields: customFields
    },
});
